import React from "react";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import { IAttestationProps } from "./Attestation";
import * as S from "./Attestation.styles";

const SynthixV1: React.FC<IAttestationProps> = ({
    projectTeamAssignment
}) => (<>
    <S.PageTitleSection>
        <Container isResponsive={true}>
            <Row>
                <Column>
                    <Heading variant="h5" weight={300}>
                        Community agreement
                    </Heading>
                    <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Text size={20} weight={300}>
                        Project
                    </Text>
                    <Text size={20} weight={500} color="white.50">
                        {projectTeamAssignment?.ProjectName ?? projectTeamAssignment.ProjectSlug}
                    </Text>
                </Column>
            </Row>
        </Container>
    </S.PageTitleSection>
    <S.OverviewSection>
        <Container isResponsive={true}>
            <Row>
                <Column md={7}>
                    <Heading variant="h5" color="white.75" style={{ marginBottom: 16 }}>
                        Overview
                    </Heading>
                    <Text color="white.75">
                        Breaking Wave DB Ltd. (Breaking Wave) is part of the Deutsche Bank group of
                        companies. Breaking Wave is air-gapped both physically through separate office
                        space and hardware, and virtually through a separate software and development
                        environment. The air-gap facilitates compliant project collaboration with
                        Deutsche Bank in a modern software development environment.
                        <br />
                        <br />
                        In order to reduce its inherent risk, require fewer controls and remain within risk 
                        appetite,  Breaking Wave development environment is “air-gapped” from the rest 
                        of the Bank. It is imperative that DB&apos;s Confidential and/or unmasked client data 
                        does not enter the environment. Hence, all users are prohibited from 
                        sending/receiving or using this type of data.
                        <br />
                        <br />                        
                        As a member of our Community, you are granted access to a version of Breaking
                        Wave&apos;s development environment. The service grants you access to a set of Engineering
                        Tools, Vendor Solutions, or Technologies; or potentially a mixture of all three.
                        <br />
                        <br />
                        This document is to confirm the overlay of additional policies and governance
                        necessary to protect Breaking Wave. We ask that you confirm your understanding
                        of your engagement with Breaking Wave by email attestation.
                        <br />
                        <br />
                        This document is a Compliance requirement, not an offer of employment with
                        Breaking Wave. It fits alongside the statement of work for your project.
                    </Text>
                    <S.DescriptionListWrapper>
                        <dl>
                            <dt>
                                <Text color="white.75">Project</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    The details of your project are specified in your statement of work.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">Location</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    The Breaking Wave Community is accessed remotely. If needed, you should discuss 
                                    the requirement to access any Deutsche Bank premises as a visitor directly with 
                                    your project sponsor.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">Breaking Wave Contact</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    Community Support
                                    <br />
                                    <a
                                        href="mailto:community@breakingwave.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <u>community@breakingwave.com</u>
                                    </a>
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">Duration of your Access period</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    Start and end dates are detailed in your Statement of Work but may
                                    change according to the progress of the project you are working on.
                                    This will be discussed regularly with you via the progress updates
                                    detailed in the statement of work.
                                    <br />
                                    <br />
                                    You should only use the assets and services (including email)
                                    provided to you by Breaking Wave for the purpose of your Breaking
                                    Wave project, and always in a professional manner and in compliance 
                                    with Deutsche Bank&apos;s policies and procedures.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">Information</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    In order to reduce its inherent risk, require fewer controls and remain 
                                    within risk appetite, Breaking Wave development environment is “air-gapped” 
                                    from the rest of the Bank. It is imperative that DB&apos;s Confidential and/or 
                                    unmasked client data does not enter the environment. Hence, all users 
                                    are prohibited from sending/receiving or using this type of data. 
                                    <br />
                                    <br />
                                    If you are unsure of the nature of the data you want to use is at risk 
                                    of breaching the above or if you identify any of such data in your 
                                    project space, please contact Breaking Wave support for guidance. 
                                    <br />
                                    <br />
                                    Information falling under the &lsquo;Need to Know&rsquo; Principles relates to 
                                    information required for your project, and should reflect your role/function 
                                    within your project. You may send and communicate project information 
                                    that falls under the &lsquo;Need to Know&rsquo; principles via email or other agreed mechanisms.
                                    <br />
                                    <br />
                                    Outside of project related information, Breaking Wave should never
                                    come into contact with client or trade information, or information
                                    that is deemed &lsquo;confidential&rsquo; at any time. If you have a
                                    separate engagement with Deutsche Bank, please treat Breaking Wave
                                    and Deutsche Bank as separate, non-associated engagements.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">System</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    You will be provided a Breaking Wave profile and email account which
                                    facilitates the use of both communication and engineering (should
                                    you require it) tools. You may use these tools in relation to your
                                    Deutsche Bank project, however these tools must not be used in
                                    relation to your other work.
                                    <br />
                                    <br />
                                    All Breaking Wave assets and services fall under Deutsche Bank
                                    guidelines for appropriate use in respect to the type of content
                                    accessed, and types of activity undertaken. Should a Breaking Wave
                                    laptop be provisioned, the laptop remains the property of Breaking
                                    Wave.
                                </Text>
                            </dd>
                        </dl>
                    </S.DescriptionListWrapper>
                </Column>
            </Row>
        </Container>
    </S.OverviewSection>
    <S.AttestationSection>
        <Container isResponsive={true}>
            <Row>
                <Column md={7}>
                    <Heading variant="h4" style={{ marginBottom: 16 }}>
                        Attestation
                    </Heading>
                    <Text color="white.75">
                        I hereby attest that I understand my obligations relating to obtaining access to
                        the Breaking Wave Community, in accordance with the information outlined above.
                        <br />
                        <br />
                        Furthermore and specifically, I understand the importance of not disclosing nor
                        using, in any manner, any Deutsche Bank non‑public information
                        within the Breaking Wave environment, outside of information relating to my
                        project. Any separate engagement with Deutsche Bank, not relating to my project,
                        will be considered as mutually exclusive.
                        <br />
                        <br />
                        Additionally, I confirm that I shall only use my Breaking Wave credentials to
                        conduct Breaking Wave related activity – including the use of provisioned
                        communication channels.
                        <br />
                        <br />
                        Moreover I agree to disclose if I am subject to an MTA (Mandatory Time Away)
                        obligation. Should this be the case I will notify Breaking Wave and the Project
                        Administrator of my absence period and will not access the Breaking Wave environment
                        during this time.
                        <br />
                        <br />
                        Through submitting this form, I confirm my understanding and acceptance of the
                        engagement terms with Breaking Wave. I understand that this is an agreement to
                        gain access to Breaking Wave services, and not an employment contract.
                    </Text>
                </Column>
            </Row>
        </Container>
    </S.AttestationSection>
</>);

export default SynthixV1;
